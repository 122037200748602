import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers';

export const fetchGallery = createAsyncThunk(
	'gallerySlice/fetchGallery',
	async (language = 'ar', { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/gallery',
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const gallerySlice = createSlice({
	initialState: {
		gallery: [],
		isGalleryLoading: true,
	},
	name: 'gallerySlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchGallery.pending, (state, action) => {
			state.isGalleryLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchGallery.fulfilled, (state, action) => {
			state.gallery = action.payload;
			state.isGalleryLoading = false;
		});

		// Rejected
		builder.addCase(fetchGallery.rejected, (state, action) => {
			console.log('rejected:', action.payload);
			state.isGalleryLoading = true;
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = gallerySlice.actions;

// Reducer
export default gallerySlice.reducer;
