import { configureStore } from '@reduxjs/toolkit';

// Slices
import aboutDataReducer from './reducers/aboutData.reducer';
import galleryReducer from './reducers/gallery.reducer';
import homeDataReducer from './reducers/homeData.reducer';
import servicesReducer from './reducers/services.reducer';
import settingsDataReducer from './reducers/settingsData.reducer';

export const store = configureStore({
	reducer: {
		homeData: homeDataReducer,
		gallery: galleryReducer,
		services: servicesReducer,
		aboutData: aboutDataReducer,
		settingsData: settingsDataReducer,
	},

	devTools: true,
});
