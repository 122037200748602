import React, { useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { REGEX, replacePathVariables } from './../../helpers';
import { routes } from './../../routes/index.routes';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchService } from './../../store/reducers/services.reducer';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './ServiceDetailsPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import LoadingComponent from './../../components/LoadingComponent/LoadingComponent';

const ServiceDetailsPage = () => {
	// i18next
	const { lang, service_id } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: routes.home.replace(REGEX, function (matched) {
				return replacePathVariables(matched, {
					lang: lang,
				});
			}),
			isActive: false,
		},
		{
			title: t('words:breadcrumb.services'),
			href: routes.services.root.replace(REGEX, function (matched) {
				return replacePathVariables(matched, {
					lang: lang,
				});
			}),
			isActive: false,
		},
		{
			title: t('words:breadcrumb.serviceDetails'),
			href: '',
			isActive: true,
		},
	];

	// Redux
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(
			fetchService({ language: lang ?? 'ar', searchParams: { id: service_id } })
		);
		// eslint-disable-next-line
	}, [lang]);

	const { service, isSingleServiceLoading } = useSelector(
		(state) => state.services
	);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return isSingleServiceLoading ? (
		<LoadingComponent />
	) : (
		<Container
			fluid
			lang={lang ?? 'ar'}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			id='service-details-page'
			className='page px-0'
			style={{
				minHeight: '100vh',
			}}
		>
			{/* Breadcrumb */}
			<BreadcrumbComponent title={service?.title} items={breadcrumbItems} />

			{/* Content */}
			<Container className='py-4'>
				<Row xs={1} md={2} className='g-4 overflow-hidden'>
					{/* Image Container */}
					<Col>
						<Fade
							direction={lang === 'en' ? 'left' : 'right'}
							className='h-100'
						>
							<Image
								fluid
								src={service?.image}
								alt='service image'
								className='image text-capitalize h-100'
								style={{
									objectFit: 'cover',
									objectPosition: 'center',
								}}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = require('./../../assets/images/logos/logo.png');
								}}
							/>
						</Fade>
					</Col>

					{/* Text Container */}
					<Col className='text-container text-capitalize'>
						<Row xs={1} className='g-4'>
							<Fade direction='up' cascade={true}>
								{/* Title */}
								<Col className='title display-6 h3 px-0'>{service?.title}</Col>

								{/* Short Description */}
								<Col
									className='short-description'
									dangerouslySetInnerHTML={{
										__html: service?.short_description,
									}}
								></Col>

								{/* Main Description */}
								<Col
									className='description'
									dangerouslySetInnerHTML={{ __html: service?.description }}
								></Col>
							</Fade>
						</Row>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default ServiceDetailsPage;
