import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Routes
import { isMultilingual, routes } from './routes/index.routes';

// Pages
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import HomePage from './pages/HomePage/HomePage';
import MainTemplatePage from './pages/MainTemplatePage/MainTemplatePage';
import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import ServiceDetailsPage from './pages/ServiceDetailsPage/ServiceDetailsPage';
import ServicesPage from './pages/ServicesPage/ServicesPage';

const App = () => {
	return (
		<BrowserRouter>
			{/* Available Routes */}
			<Routes>
				{/* Home Page */}
				{isMultilingual ? (
					<Route path='/' element={<Navigate to={routes.fallback} />} />
				) : (
					<>
						<Route path='/ar' element={<Navigate to={routes.fallback} />} />
						<Route path='/en' element={<Navigate to={routes.fallback} />} />
					</>
				)}
				<Route
					exact
					path={routes.home}
					element={
						<MainTemplatePage>
							<HomePage />
						</MainTemplatePage>
					}
				/>

				{/* About Page */}
				<Route
					path={routes.about}
					element={
						<MainTemplatePage>
							<AboutPage />
						</MainTemplatePage>
					}
				/>

				{/* Contact Page */}
				<Route
					path={routes.contact}
					element={
						<MainTemplatePage>
							<ContactPage />
						</MainTemplatePage>
					}
				/>

				{/* Gallery Page */}
				<Route
					path={routes.gallery}
					element={
						<MainTemplatePage>
							<GalleryPage />
						</MainTemplatePage>
					}
				/>

				{/* Services Page */}
				<Route
					path={routes.services.root}
					element={
						<MainTemplatePage>
							<ServicesPage />
						</MainTemplatePage>
					}
				/>

				{/* Service Details Page */}
				<Route
					path={routes.services.single}
					element={
						<MainTemplatePage>
							<ServiceDetailsPage />
						</MainTemplatePage>
					}
				/>

				{/* Not Found Page: Error 404 */}
				<Route path={routes.notFound} element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
