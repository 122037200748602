import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from './../../helpers';

export const fetchServices = createAsyncThunk(
	'servicesSlice/fetchServices',
	async ({ language = 'ar', searchParams = {} }, { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/services',
			params: {
				id: searchParams?.id,
			},
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const fetchService = createAsyncThunk(
	'servicesSlice/fetchService',
	async ({ language = 'ar', searchParams = {} }, { rejectWithValue }) => {
		return await axios({
			method: 'GET',
			baseURL: BASE_URL.demo,
			url: '/services',
			params: {
				id: searchParams?.id,
			},
			headers: {
				locale: language,
			},
		})
			.then((response) => {
				return response.data.data;
			})
			.catch((error) => rejectWithValue(error.message));
	}
);

export const servicesSlice = createSlice({
	initialState: {
		services: [],
		service: {},
		isServicesLoading: true,
		isSingleServiceLoading: true,
	},
	name: 'servicesSlice',
	reducers: {},
	extraReducers: (builder) => {
		// Pending
		builder.addCase(fetchServices.pending, (state, action) => {
			state.isServicesLoading = true;
		});
		builder.addCase(fetchService.pending, (state, action) => {
			state.isSingleServiceLoading = true;
		});

		// Fulfilled
		builder.addCase(fetchServices.fulfilled, (state, action) => {
			state.services = action.payload;
			state.isServicesLoading = false;
		});
		builder.addCase(fetchService.fulfilled, (state, action) => {
			state.service = action.payload;
			state.isSingleServiceLoading = false;
		});

		// Rejected
		builder.addCase(fetchServices.rejected, (state, action) => {
			console.log('rejected:', action.payload);
			state.isServicesLoading = true;
		});
		builder.addCase(fetchService.rejected, (state, action) => {
			console.log('rejected:', action.payload);
			state.isSingleServiceLoading = true;
		});
	},
});

// Actions
// eslint-disable-next-line
export const {} = servicesSlice.actions;

// Reducer
export default servicesSlice.reducer;
