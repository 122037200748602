import React from 'react';
import { Card, Container } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useParams } from 'react-router-dom';

// Styles
import './StatisticsCardComponent.styles.css';

const StatisticsCardComponent = ({ counter }) => {
	const { lang } = useParams();

	return (
		<Container
			lang={lang ?? 'ar'}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			fluid
			className='statistics-card-component m-0'
		>
			<Card className='statistics-card-container'>
				<Card.Body className='text-center'>
					{/* Icon */}
					<Card.Text className={`icon ${counter.icon}`}></Card.Text>

					{/* Number */}
					<Card.Title className='number'>
						<CountUp
							start={0}
							end={counter.number}
							duration={3}
							separator=''
							decimals={0}
							decimal=','
							prefix=''
							suffix='+'
							startOnMount={false}
							enableScrollSpy={true}
						></CountUp>
					</Card.Title>

					{/* Title */}
					<Card.Text
						className='title text-limit'
						style={{
							'--lines': 2,
						}}
					>
						{counter.title}
					</Card.Text>
				</Card.Body>
			</Card>
		</Container>
	);
};

export default StatisticsCardComponent;
