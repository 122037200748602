import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Fade } from 'react-awesome-reveal';
import {
	Col,
	Container,
	FormGroup,
	FormLabel,
	FormText,
	ListGroup,
	Row,
	Spinner,
} from 'react-bootstrap';
import { BsEnvelope, BsPhone } from 'react-icons/bs';
import {
	FaFacebookF,
	FaGlobe,
	FaInstagram,
	FaLinkedinIn,
	FaTwitter,
	FaYoutube,
} from 'react-icons/fa';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineLocationOn } from 'react-icons/md';
import PhoneInput from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import {
	BASE_URL,
	REGEX,
	checkURL,
	fixGoogleMaps,
	replacePathVariables,
} from './../../helpers';
import { routes } from './../../routes/index.routes';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './ContactPage.styles.css';

// Components
import IconTextComponent from '../../components/IconTextComponent/IconTextComponent';
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';

const ContactPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: routes.home.replace(REGEX, function (matched) {
				return replacePathVariables(matched, {
					lang: lang,
				});
			}),
			isActive: false,
		},
		{
			title: t('words:breadcrumb.contactUs'),
			href: '',
			isActive: true,
		},
	];

	const ICON = { SIZE: 24 };

	// Redux
	const {
		settings: { address, map },
		contacts: {
			mobile: mobiles,
			telephone: telephones,
			email: emails,
			social: socials,
		},
	} = useSelector((state) => state.settingsData);

	// Refs
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const emailRef = useRef(null);
	const phoneRef = useRef(null);
	const messageRef = useRef(null);

	// Schema
	const contactSchema = object().shape({
		fname: string()
			.min(2, t('validations:firstName.min', { min: 2 }))
			.max(100, t('validations:firstName.max', { max: 100 }))
			.required(t('validations:firstName.required')),
		lname: string()
			.min(2, t('validations:lastName.min', { min: 2 }))
			.max(100, t('validations:lastName.max', { max: 100 }))
			.required(t('validations:lastName.required')),
		email: string()
			.email(t('validations:email.format'))
			.required(t('validations:email.required')),
		phone: string()
			.min(6, t('validations:phone.min', { min: 6 }))
			.matches(/^[0-9+]+/, t('validations:phone.format'))
			.required(t('validations:phone.required')),
		message: string()
			.min(2, t('validations:message.min', { min: 2 }))
			.max(500, t('validations:message.max', { max: 500 }))
			.required(t('validations:message.required')),
	});

	// Handle Form Errors
	const displayErrors = (fieldName) => {
		switch (fieldName) {
			case 'fname':
				firstNameRef.current.classList.add('is-invalid');
				break;

			case 'lname':
				lastNameRef.current.classList.add('is-invalid');
				break;

			case 'email':
				emailRef.current.classList.add('is-invalid');
				break;

			case 'phone':
				phoneRef.current.classList.add('is-invalid');
				break;

			case 'message':
				messageRef.current.classList.add('is-invalid');
				break;

			default:
				break;
		}
	};

	// Display Form Errors
	const displayToast = (statusCode, message) => {
		switch (statusCode) {
			case 200:
				toast.success(message, {
					toastId: message,
				});
				break;
			case 400:
				toast.error(message, {
					toastId: message,
				});
				break;
			default:
				toast.error(t('sentences:errors.default'));
				break;
		}
	};

	const submitContactForm = async (
		values,
		setSubmitting,
		resetForm,
		language = 'ar'
	) => {
		axios({
			method: 'POST',
			baseURL: BASE_URL.demo,
			url: '/contact',
			data: {
				fname: values.fname,
				lname: values.lname,
				email: values.email,
				phone: values.phone,
				message: values.message,
			},
			headers: { locale: language, 'Content-Type': 'multipart/form-data' },
		})
			.then((response) => {
				// reset submitting
				setSubmitting(false);
				resetForm(true);

				displayToast(response.status, response.data.message);
			})
			.catch((error) => {
				// reset submitting
				setSubmitting(false);
				if (error.response.data.data !== {}) {
					Object.keys(error.response.data.data).forEach((key) => {
						displayErrors(key);
						displayToast(
							error.response.status,
							error.response.data.data[key][0]
						);
					});
				} else {
					displayToast(error.response.status, error.response.data.message);
				}
			});
	};

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			fluid
			lang={lang ?? 'ar'}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			id='contact-us-page'
			className='page px-0'
			style={{
				minHeight: '100vh',
			}}
		>
			{/* Breadcrumb */}
			<BreadcrumbComponent
				title={t('words:breadcrumb.contactUs')}
				items={breadcrumbItems}
			/>

			{/* Content */}
			<Container>
				{/* Map */}
				<Row xs={1} className='mb-5 g-3 overflow-hidden'>
					<Fade direction='down' delay='30'>
						<Col
							dangerouslySetInnerHTML={{
								__html: fixGoogleMaps(map),
							}}
						></Col>
					</Fade>
				</Row>

				{/* Contact Form */}
				<Row xs={1} md={2} className='gx-5 gy-4'>
					{/* Contact Details Container */}
					<Col className='text-container d-flex flex-column justify-content-center align-items-center overflow-hidden'>
						<Fade direction={lang === 'en' ? 'right' : 'left'} delay={20}>
							<Row
								xs={1}
								className='text py-4 d-flex flex-column justify-content-center align-items-center'
							>
								<Col
									xs={12}
									className='d-flex flex-column justify-content-center align-items-center'
								>
									{/* Title */}
									<h3 className='title display-6 h3 px-0 text-center text-uppercase my-4'>
										{t('sentences:findUs')}
									</h3>
								</Col>
							</Row>

							{/* Contact Info */}
							<Row as='ul' className='w-100'>
								{/* Address */}
								<Col xs={12} as='li'>
									<IconTextComponent
										icon={<MdOutlineLocationOn />}
										text={address}
										isCentered={false}
										styles={{
											container: {
												'--size': ICON.SIZE,
												marginBottom: '0.5rem',
											},
										}}
									/>
								</Col>

								{/* Telephones */}
								{telephones.length > 0 &&
									telephones.map((telephone, index) => (
										<Col key={index} xs={12} as='li'>
											<IconTextComponent
												icon={<FiPhoneCall />}
												text={
													<Container
														fluid
														className='value p-0'
														style={{
															direction: 'ltr',
														}}
													>
														<a href={`tel:${telephone.contact}`}>
															{telephone.contact}
														</a>
													</Container>
												}
												isCentered={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
														marginBottom: '0.5rem',
													},
												}}
											/>
										</Col>
									))}

								{/* Phones */}
								{mobiles.length > 0 &&
									mobiles.map((mobile, index) => (
										<Col key={index} xs={12} as='li'>
											<IconTextComponent
												icon={<BsPhone />}
												text={
													<Container
														fluid
														className='value p-0'
														style={{
															direction: 'ltr',
														}}
													>
														<a href={`tel:${mobile.contact}`}>
															{mobile.contact}
														</a>
													</Container>
												}
												isCentered={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
														marginBottom: '0.5rem',
													},
												}}
											/>
										</Col>
									))}

								{/* Emails */}
								{emails.length > 0 &&
									emails.map((email, index) => (
										<Col key={index} xs={12} as='li'>
											<IconTextComponent
												icon={<BsEnvelope />}
												text={
													<Container fluid key={index} className='value p-0'>
														<a href={`mailto:${email.contact}`}>
															{email.contact}
														</a>
													</Container>
												}
												isCentered={false}
												isCapitalized={false}
												styles={{
													container: {
														'--size': ICON.SIZE,
													},
												}}
											/>
										</Col>
									))}

								{/* Socials */}
								<Col
									xs={12}
									as={ListGroup}
									horizontal
									className='social-accounts d-flex align-items-center justify-content-center px-0'
								>
									{socials.map((social, index) => (
										<ListGroup.Item
											key={index}
											as='a'
											href={checkURL(social.contact)}
											target='_blank'
											style={{
												'--icon-color': social.contact.includes('facebook')
													? '#1877f2'
													: social.contact.includes('twitter')
													? '#1da1f2'
													: social.contact.includes('linkedin')
													? '#0077b5'
													: social.contact.includes('instagram')
													? '#f56040'
													: social.contact.includes('youtube')
													? '#ff0000'
													: '#34bf49',
											}}
										>
											{social.contact.includes('facebook') ? (
												<FaFacebookF size={20} />
											) : social.contact.includes('twitter') ? (
												<FaTwitter size={20} />
											) : social.contact.includes('linkedin') ? (
												<FaLinkedinIn size={20} />
											) : social.contact.includes('instagram') ? (
												<FaInstagram size={20} />
											) : social.contact.includes('youtube') ? (
												<FaYoutube size={20} />
											) : (
												<FaGlobe size={20} />
											)}
										</ListGroup.Item>
									))}
								</Col>
							</Row>
						</Fade>
					</Col>

					{/* Contact Form */}
					<Fade direction='vertical' delay={50}>
						<Col className='d-flex justify-content-center align-items-center overflow-hidden'>
							<Formik
								initialValues={{
									fname: '',
									lname: '',
									email: '',
									phone: '',
									message: '',
								}}
								validationSchema={contactSchema}
								onSubmit={(values, { setSubmitting, resetForm }) => {
									setSubmitting(true);
									submitContactForm(values, setSubmitting, resetForm, lang);
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
								}) => (
									<Form
										onSubmit={(event) => {
											event.preventDefault();
											handleSubmit();
										}}
										className='p-4 overflow-hidden'
										style={{
											width: '40rem',
										}}
									>
										<Row xs={1} sm={2} md={1} lg={2}>
											{/* First Name */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													lang === 'en'
														? 'animate__fadeInLeft'
														: 'animate__fadeInRight'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.5s',
												}}
											>
												<FormLabel
													htmlFor='first_name'
													className='text-capitalize'
												>
													{t('words:labels.firstName')}
												</FormLabel>
												<Field
													id='first_name'
													type='text'
													innerRef={firstNameRef}
													placeholder={t('words:placeholders.firstName')}
													autoComplete='off'
													name='fname'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.fname}
													className={`form-control text-capitalize ${
														touched.fname && errors.fname ? 'is-invalid' : ''
													}`}
												/>
												<ErrorMessage
													component='div'
													name='fname'
													className='invalid-feedback'
												/>
											</FormGroup>

											{/* Last Name */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													lang === 'en'
														? 'animate__fadeInRight'
														: 'animate__fadeInLeft'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.5s',
												}}
											>
												<FormLabel
													htmlFor='last_name'
													className='text-capitalize'
												>
													{t('words:labels.lastName')}
												</FormLabel>
												<Field
													id='last_name'
													type='text'
													innerRef={lastNameRef}
													placeholder={t('words:placeholders.lastName')}
													autoComplete='off'
													name='lname'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.lname}
													className={`form-control text-capitalize ${
														touched.lname && errors.lname ? 'is-invalid' : ''
													}`}
												/>
												<ErrorMessage
													component='div'
													name='lname'
													className='invalid-feedback'
												/>
											</FormGroup>

											{/* Email */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													lang === 'en'
														? 'animate__fadeInLeft'
														: 'animate__fadeInRight'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.75s',
												}}
											>
												<FormLabel htmlFor='email' className='text-capitalize'>
													{t('words:labels.email')}
												</FormLabel>
												<Field
													id='email'
													type='email'
													innerRef={emailRef}
													placeholder='mail@domain.com'
													autoComplete='off'
													name='email'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.email}
													className={`form-control ${
														touched.email && errors.email ? 'is-invalid' : ''
													}`}
												/>
												<ErrorMessage
													component='div'
													name='email'
													className='invalid-feedback'
												/>
											</FormGroup>

											{/* Mobile Number */}
											<FormGroup
												as={Col}
												className={`mb-3 animate__animated ${
													lang === 'en'
														? 'animate__fadeInRight'
														: 'animate__fadeInLeft'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '0.75s',
												}}
											>
												<FormLabel htmlFor='phone' className='text-capitalize'>
													{t('words:labels.phone')}
												</FormLabel>
												<Field name='phone' innerRef={phoneRef}>
													{(field, form, meta) => (
														<>
															<PhoneInput
																{...field}
																id='phone'
																dir='ltr'
																ref={phoneRef}
																placeholder='56 123 0620'
																defaultCountry='SA'
																autoComplete='off'
																onChange={(event) => {
																	setFieldValue('phone', event);
																}}
																onBlur={handleBlur}
																value={values.phone}
																className={`${
																	field.meta.touched && field.meta.error
																		? 'is-invalid'
																		: ''
																}`}
															/>
															{field.meta.error && (
																<Container
																	fluid
																	className='invalid-feedback p-0'
																>
																	{field.meta.error}
																</Container>
															)}
														</>
													)}
												</Field>
											</FormGroup>
										</Row>

										<Row xs={1}>
											{/* Message */}
											<FormGroup
												as={Col}
												className={`mb-3 position-relative animate__animated ${
													lang === 'en'
														? 'animate__fadeInLeft'
														: 'animate__fadeInRight'
												} animate__delay-1s`}
												style={{
													'--animate-delay': '1s',
												}}
											>
												<FormLabel
													htmlFor='message'
													className='text-capitalize'
												>
													{t('words:labels.message')}
												</FormLabel>
												<Field
													id='message'
													as='textarea'
													innerRef={messageRef}
													rows={8}
													style={{
														resize: 'none',
													}}
													placeholder={t('words:placeholders.message')}
													autoComplete='off'
													name='message'
													onChange={(event) => {
														handleChange(event);
													}}
													onBlur={handleBlur}
													value={values.message}
													className={`form-control text-capitalize ${
														touched.message && errors.message
															? 'is-invalid'
															: ''
													}`}
												/>
												<FormText className='text-muted'>
													<span
														className={`${lang === 'en' ? 'me-1' : 'ms-1'}`}
													>
														{new Intl.NumberFormat(
															lang === 'en' ? 'en-US' : 'ar-EG',
															{}
														).format(values.message.length)}
													</span>
													<span>
														{t('words:hints.message.length', {
															max: new Intl.NumberFormat(
																lang === 'en' ? 'en-US' : 'ar-EG',
																{}
															).format(500),
														})}
													</span>
												</FormText>
												<ErrorMessage
													component='div'
													name='message'
													className='invalid-feedback'
												/>
											</FormGroup>
										</Row>

										{/* Submit Form */}
										<FormGroup
											className='d-flex justify-content-center mt-3 animate__animated animate__zoomIn animate__delay-1s'
											style={{
												'--animate-delay': '1.25s',
											}}
										>
											<ButtonComponent
												text={
													isSubmitting
														? t('words:buttons.sending')
														: t('words:buttons.submitMessage')
												}
												icon={
													isSubmitting ? (
														<Spinner
															animation='grow'
															variant='light'
															size='sm'
															className={`${lang === 'en' ? 'me-2' : 'ms-2'}`}
														/>
													) : (
														<></>
													)
												}
												styles={{
													button: {
														'--hover-bg-color': '#383633',
														'--hover-title-color': '#ffffff',
													},
												}}
												type='submit'
												disabled={isSubmitting ? true : false}
											/>
										</FormGroup>
									</Form>
								)}
							</Formik>
						</Col>
					</Fade>
				</Row>
			</Container>
		</Container>
	);
};

export default ContactPage;
