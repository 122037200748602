import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { REGEX, replacePathVariables } from './../../helpers';
import { routes } from './../../routes/index.routes';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './AboutPage.styles.css';

// Components
import AboutCardComponent from '../../components/AboutCardComponent/AboutCardComponent';
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import LightboxComponent from './../../components/LightboxComponent/LightboxComponent';

const AboutPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: routes.home.replace(REGEX, function (matched) {
				return replacePathVariables(matched, {
					lang: lang,
				});
			}),
			isActive: false,
		},
		{
			title: t('words:breadcrumb.aboutUs'),
			href: '',
			isActive: true,
		},
	];

	const { aboutUs, mission, vision } = useSelector((state) => state.aboutData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			fluid
			lang={lang ?? 'ar'}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			id='about-us-page'
			className='page px-0'
			style={{
				minHeight: '100vh',
			}}
		>
			{/* Breadcrumb */}
			<BreadcrumbComponent
				title={t('words:breadcrumb.aboutUs')}
				items={breadcrumbItems}
			/>

			{/* Content */}
			<Container>
				<Row xs={1} className='g-4'>
					{/* About Us */}
					<Col className='about-card-container pt-5'>
						<AboutCardComponent
							aboutData={aboutUs}
							index={0}
							setLightbox={setLightbox}
						/>
					</Col>

					{/* Vision */}
					<Col className='about-card-container'>
						<AboutCardComponent
							aboutData={vision}
							index={1}
							setLightbox={setLightbox}
						/>
					</Col>

					{/* Mission */}
					<Col className='about-card-container'>
						<AboutCardComponent
							aboutData={mission}
							index={2}
							setLightbox={setLightbox}
						/>
					</Col>
				</Row>

				{/* Lightbox Container */}
				<LightboxComponent
					gallery={[aboutUs, vision, mission]}
					lightbox={lightbox}
					setLightbox={setLightbox}
					pathname='<object>.image'
				/>
			</Container>
		</Container>
	);
};

export default AboutPage;
