import React from 'react';
import { Col, Container, Image, Stack } from 'react-bootstrap';

// Styles
import './TestimonialsCardComponent.styles.css';

const TestimonialsCardComponent = ({ testimonial }) => {
	return (
		<Container fluid className='testimonials-card-component px-0'>
			<Stack className='justify-content-center align-items-center'>
				{/* Image */}
				<Col className='image-container'>
					<Image
						src={testimonial?.image}
						alt='testimonial image'
						className='text-capitalize w-100 h-100'
						style={{
							objectFit: 'cover',
							objectPosition: 'center',
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
					/>
				</Col>

				{/* Full Name */}
				<Col
					className='full-name text-limit text-uppercase text-center'
					style={{ '--lines': 1 }}
				>
					{testimonial?.full_name}
				</Col>

				{/* Location */}
				<Col
					className='location text-limit text-capitalize text-center'
					style={{ '--lines': 1 }}
				>
					{testimonial?.location}
				</Col>

				{/* Description */}
				<Col
					className='description text-limit text-capitalize text-center'
					style={{ '--lines': 2 }}
					dangerouslySetInnerHTML={{ __html: testimonial?.description }}
				></Col>
			</Stack>
		</Container>
	);
};

export default TestimonialsCardComponent;
