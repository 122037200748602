import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './AboutCardComponent.styles.css';

const AboutCardComponent = ({ aboutData, index, setLightbox }) => {
	const { lang } = useParams();

	return (
		<Fade
			direction={
				index % 2 === 0
					? lang === 'en'
						? 'right'
						: 'left'
					: lang === 'en'
					? 'left'
					: 'right'
			}
		>
			<Container
				lang={lang ?? 'ar'}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				fluid
				className='about-card-component mb-5'
			>
				<Row xs={1} md={2}>
					{/* Image Container */}
					<Col className='image-container h-100'>
						<Image
							fluid
							src={aboutData?.image}
							alt='about card image'
							className='text-capitalize w-100 h-100'
							style={{
								objectFit: 'cover',
								objectPosition: 'center',
							}}
							onClick={() => setLightbox({ isOpen: true, index: index })}
							onError={({ currentTarget }) => {
								currentTarget.onerror = null; // prevents looping
								currentTarget.src = require('./../../assets/images/logos/logo.png');
							}}
						/>
					</Col>

					{/* Text Container */}
					<Col className='text-container d-flex fle-column justify-content-center align-items-center'>
						<Row xs={1} className='g-4 p-4'>
							<Col className='title display-6 h3 px-0'>{aboutData?.title}</Col>
							<Col
								className='description px-0'
								dangerouslySetInnerHTML={{ __html: aboutData?.description }}
							></Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</Fade>
	);
};

export default AboutCardComponent;
