export const isMultilingual = true;

export const routes = {
	fallback: isMultilingual ? '/ar' : '/',
	notFound: isMultilingual ? '/:lang/*' : '/*',

	home: isMultilingual ? '/:lang' : '/',

	about: isMultilingual ? '/:lang/about-us' : '/about-us',

	contact: isMultilingual ? '/:lang/contact-us' : '/contact-us',

	gallery: isMultilingual ? '/:lang/gallery' : '/gallery',

	services: {
		root: isMultilingual ? '/:lang/services' : '/services',
		single: isMultilingual
			? '/:lang/services/:service_id'
			: '/services/:service_id',
	},
};
