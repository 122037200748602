import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';

// Styles
import './ServiceCardComponent.styles.css';

const ServiceCardComponent = ({ service }) => {
	const { lang } = useParams();
	const location = useLocation();

	return (
		<Link to={`${location.pathname}/${service?.id}`}>
			<Container
				lang={lang ?? 'ar'}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				fluid
				className='service-card-component p-0 m-0'
			>
				<Card className='service-card-container overflow-hidden'>
					{/* Image */}
					<Card.Img
						fluid='true'
						src={service?.image}
						alt='service card image'
						className='image text-capitalize h-100'
						style={{
							objectFit: 'cover',
							objectPosition: 'center',
						}}
						onError={({ currentTarget }) => {
							currentTarget.onerror = null; // prevents looping
							currentTarget.src = require('./../../assets/images/logos/logo.png');
						}}
					/>

					<Card.ImgOverlay className='text-container d-flex flex-column justify-content-center'>
						{/* Title */}
						<Card.Title
							className='title display-6 h3 px-0 text-limit'
							style={{
								'--lines': 1,
							}}
						>
							{service?.title}
						</Card.Title>

						{/* Short Description */}
						<Card.Text
							className='short-description text-limit'
							style={{
								'--lines': 3,
							}}
							dangerouslySetInnerHTML={{ __html: service?.short_description }}
						></Card.Text>
					</Card.ImgOverlay>
				</Card>
			</Container>
		</Link>
	);
};

export default ServiceCardComponent;
