import React from 'react';

// Styles
import { Col, Container, Image } from 'react-bootstrap';
import './GalleryCardComponent.styles.css';

const GalleryCardComponent = ({ item, index, setLightbox }) => {
	return (
		<Container fluid className='gallery-card-component px-0'>
			<Col
				className='image-container position-relative overflow-hidden'
				onClick={() => setLightbox({ isOpen: true, index: index })}
			>
				<Image
					fluid
					src={item?.image}
					alt='gallery image'
					className='text-capitalize w-100'
					style={{
						objectFit: 'cover',
						objectPosition: 'center',
					}}
					onError={({ currentTarget }) => {
						currentTarget.onerror = null; // prevents looping
						currentTarget.src = require('./../../assets/images/logos/logo.png');
					}}
				/>

				{/* Text Container */}
				<Col className='text-container'>
					<div
						className='text-capitalize text-center text-limit'
						style={{ '--lines': 2 }}
					>
						{item?.title}
					</div>
				</Col>
			</Col>
		</Container>
	);
};

export default GalleryCardComponent;
