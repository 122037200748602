import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Styles
import './SeparatorComponent.styles.css';

// Components

const SeparatorComponent = ({ separator }) => {
	const { lang } = useParams();

	return (
		<Container
			lang={lang ?? 'ar'}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			fluid
			className='separator'
			style={{
				'--bg-image': `url(${separator?.image})`,
			}}
		>
			{/* Main Text */}
			<Fade delay={20}>
				<Container className='text-container text-center'>
					<Row
						xs={1}
						className='g-1 d-flex flex-column justify-content-center align-items-center'
					>
						{/* Subtitle */}
						<Fade direction='up' delay={40}>
							{separator?.sub_title && (
								<Col className='subtitle'>{separator?.sub_title}</Col>
							)}
						</Fade>

						{/* Main Title */}
						<Fade direction='up' delay={60}>
							{separator?.title && (
								<Col className='title text-uppercase'>{separator?.title}</Col>
							)}
						</Fade>

						{/* Main Description */}
						<Fade direction='up' delay={80}>
							{separator?.description && (
								<Col
									dangerouslySetInnerHTML={{ __html: separator?.description }}
									className='description text-capitalize'
								></Col>
							)}
						</Fade>
					</Row>

					{/* Decorations */}
					<div className='top'></div>
					<div className='bottom'></div>
				</Container>
			</Fade>
		</Container>
	);
};

export default SeparatorComponent;
