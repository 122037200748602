import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { isMultilingual } from '../../routes/index.routes';
import { availableLanguages } from './../../helpers';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { fetchAboutData } from './../../store/reducers/aboutData.reducer';
import { fetchHomeData } from './../../store/reducers/homeData.reducer';
import { fetchServices } from './../../store/reducers/services.reducer';
import { fetchSettingsData } from './../../store/reducers/settingsData.reducer';

// Styles
import 'react-toastify/dist/ReactToastify.css';

// Components
import GoToTopComponent from '../../components/GoToTopComponent/GoToTopComponent';
import MetaTagsComponent from '../../components/MetaTagsComponent/MetaTagsComponent';
import WhatsappIconComponent from '../../components/WhatsappIconComponent/WhatsappIconComponent';
import FooterComponent from './../../components/FooterComponent/FooterComponent';
import LoadingComponent from './../../components/LoadingComponent/LoadingComponent';
import NavbarComponent from './../../components/NavbarComponent/NavbarComponent';

const MainTemplatePage = ({ children }) => {
	// Handle Language Change
	const { lang } = useParams();
	const location = useLocation();
	const navigate = useNavigate();

	// Handle Wrong Languages Entered By User In URL
	useEffect(() => {
		if (isMultilingual) {
			if (!availableLanguages.includes(lang)) {
				navigate(location.pathname.replace(`/${lang}`, '/ar'));
			}
		}
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchHomeData(lang ?? 'ar'));
		dispatch(fetchAboutData(lang ?? 'ar'));
		dispatch(fetchServices({ language: lang ?? 'ar', searchParams: {} }));
		dispatch(fetchSettingsData(lang ?? 'ar'));
		// eslint-disable-next-line
	}, [lang]);

	const { isSettingsLoading } = useSelector((state) => state.settingsData);
	const { isHomeDataLoading } = useSelector((state) => state.homeData);
	const { isServicesLoading } = useSelector((state) => state.services);
	const { isAboutDataLoading } = useSelector((state) => state.aboutData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return isSettingsLoading ||
		isHomeDataLoading ||
		isServicesLoading ||
		isAboutDataLoading ? (
		<LoadingComponent />
	) : (
		<>
			{/* Page Navbar */}
			<NavbarComponent />
			{/* Current Page Content */}
			{children}
			{/* Page Footer */}
			<FooterComponent />
			{/* Whatsapp Icon */}
			<WhatsappIconComponent />
			{/* Go To Top Button */}
			<GoToTopComponent />
			{/* Meta Tags */}
			<MetaTagsComponent />
			{/* Toast Messages */}
			<ToastContainer
				position='top-right'
				autoClose={6000}
				newestOnTop
				pauseOnHover
				rtl={lang === 'en' ? false : true}
			/>
		</>
	);
};

export default MainTemplatePage;
