import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';

// i18next
import { useTranslation } from 'react-i18next';

// Styles
import './GalleryPage.styles.css';

// Components
import BreadcrumbComponent from './../../components/BreadcrumbComponent/BreadcrumbComponent';
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';
import GalleryCardComponent from './../../components/GalleryCardComponent/GalleryCardComponent';
import LightboxComponent from './../../components/LightboxComponent/LightboxComponent';

const GalleryPage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang);
		// eslint-disable-next-line
	}, [lang]);

	const breadcrumbItems = [
		{
			title: t('words:breadcrumb.home'),
			href: `/${lang}`,
			isActive: false,
		},
		{
			title: t('words:breadcrumb.gallery'),
			href: '',
			isActive: true,
		},
	];

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });
	const [galleryLimit, setGalleryLimit] = useState(6);

	// Redux
	const { gallery } = useSelector((state) => state.homeData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			fluid
			lang={lang}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			id='gallery-page'
			className='page p-0'
		>
			{/* Breadcrumb */}
			<BreadcrumbComponent
				title={t('words:breadcrumb.gallery')}
				items={breadcrumbItems}
			/>

			<Container>
				{/* Images Container */}
				<Row xs={1} sm={2} md={3} className='g-4'>
					{/* Actual Images */}
					{gallery
						?.filter((_, index) => index < galleryLimit)
						?.map((galleryItem, index) => (
							<Fade key={index} direction='up' delay={`${index * 5}`}>
								<Col>
									<GalleryCardComponent
										item={galleryItem}
										index={index}
										setLightbox={setLightbox}
									/>
								</Col>
							</Fade>
						))}

					{/* Lightbox Container */}
					<LightboxComponent
						gallery={gallery}
						lightbox={lightbox}
						setLightbox={setLightbox}
						pathname='<object>.image'
					/>
				</Row>

				{galleryLimit <= gallery.length && (
					<Row xs={1} className='mt-5 overflow-hidden'>
						{/* Action Button */}
						<Fade direction='down' delay={80}>
							<Col className='d-flex justify-content-center align-items-center'>
								<ButtonComponent
									text={t('words:buttons.viewMore')}
									className='button'
									styles={{
										button: {
											'--hover-bg-color': '#383633',
											'--hover-title-color': '#eae9e5',
										},
									}}
									onClick={() => {
										setGalleryLimit(galleryLimit + 6);
										setTimeout(() => {
											window.scrollBy({
												behavior: 'smooth',
												top: window.innerHeight * 0.25,
											});
										}, 0);
									}}
								/>
							</Col>
						</Fade>
					</Row>
				)}
			</Container>
		</Container>
	);
};

export default GalleryPage;
