import React, { useEffect, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { REGEX, replacePathVariables } from './../../helpers';
import { routes } from './../../routes/index.routes';

// Redux
import { useSelector } from 'react-redux';

// Styles
import './HomePage.styles.css';

// Components
import ButtonComponent from './../../components/ButtonComponent/ButtonComponent';
import GalleryCardComponent from './../../components/GalleryCardComponent/GalleryCardComponent';
import LightboxComponent from './../../components/LightboxComponent/LightboxComponent';
import MainSliderComponent from './../../components/MainSliderComponent/MainSliderComponent';
import PartnersComponent from './../../components/PartnersComponent/PartnersComponent';
import SeparatorComponent from './../../components/SeparatorComponent/SeparatorComponent';
import StatisticsCardComponent from './../../components/StatisticsCardComponent/StatisticsCardComponent';
import TestimonialsSliderComponent from './../../components/TestimonialsComponent/TestimonialsSliderComponent';

const HomePage = () => {
	// i18next
	const { lang } = useParams();
	const { t, i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	const MAX_GALLERY_IMAGES = 6;

	// States
	const [lightbox, setLightbox] = useState({ isOpen: false, index: 0 });

	// Redux
	const { sliders, aboutUs, statistics, separators, partners, gallery } =
		useSelector((state) => state.homeData);

	// Scroll To Top On Initial Render
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [lang]);

	return (
		<Container
			lang={lang ?? 'ar'}
			// dir={lang === 'en' ? 'ltr' : 'rtl'} // // crashes within <Marquee></Marquee>
			fluid
			id='home-page'
			className='page overflow-hidden'
		>
			{/* Main Hero Section */}
			<MainSliderComponent sliders={sliders} />

			{/* About Section */}
			<Container
				lang={lang ?? 'ar'}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				fluid
				id='about-us-section'
			>
				<Container>
					<Row xs={1} md={2} className='g-4'>
						<Fade direction={lang === 'en' ? 'left' : 'right'} delay={50}>
							{/* Image Container */}
							<Col className='image-container position-relative'>
								<Image
									fluid
									src={aboutUs?.image}
									alt='about us image'
									className='text-capitalize w-100 h-100'
									style={{
										objectFit: 'cover',
										objectPosition: 'center',
										height: window.navigator.userAgent
											.toLowerCase()
											.includes('mac')
											? 'unset'
											: '100%',
									}}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null; // prevents looping
										currentTarget.src = require('./../../assets/images/logos/logo.png');
									}}
								/>
							</Col>
						</Fade>

						{/* Text Container */}
						<Col className='text-container text-capitalize overflow-hidden'>
							<Row xs={1} className='g-4'>
								{/* SubTitle */}
								<Fade direction='up' delay={20}>
									<Col className='subtitle'>{aboutUs?.sub_title}</Col>
								</Fade>

								{/* Title */}
								<Fade direction='up' delay={40}>
									<Col className='title'>{aboutUs?.title}</Col>
								</Fade>

								{/* Description */}
								<Fade direction='up' delay={60}>
									<Col
										dangerouslySetInnerHTML={{ __html: aboutUs?.description }}
										className='description'
									></Col>
								</Fade>

								{/* Action Button */}
								<Fade direction='up' delay={80}>
									<Col>
										<ButtonComponent
											text={t('words:buttons.viewMore')}
											link={routes.about.replace(REGEX, function (matched) {
												return replacePathVariables(matched, {
													lang: lang,
												});
											})}
											className='button'
											styles={{
												button: {
													'--hover-bg-color': '#383633',
													'--hover-title-color': '#eae9e5',
												},
											}}
										/>
									</Col>
								</Fade>
							</Row>
						</Col>
					</Row>
				</Container>
			</Container>

			{/* Statistics */}
			<Container
				lang={lang ?? 'ar'}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='statistics-section'
				className='overflow-hidden'
			>
				<Row className='g-4'>
					{/* Text Container */}
					<Col xs={12} md={5}>
						<Row xs={1} className='text-container g-4'>
							<Col className='title text-capitalize display-6 h3 px-0'>
								{statistics?.title}
							</Col>
							<Col
								className='description m-0 p-0'
								dangerouslySetInnerHTML={{
									__html: statistics?.description,
								}}
							></Col>
						</Row>
					</Col>

					{/* Counters Container */}
					<Col xs={12} md={7}>
						<Row xs={1} sm={2} xl={3} className='g-4'>
							{statistics?.counters.map((counter, index) => (
								<Fade key={index} direction='up' delay={index * 100}>
									<Col>
										<StatisticsCardComponent counter={counter} />
									</Col>
								</Fade>
							))}
						</Row>
					</Col>
				</Row>
			</Container>

			{/* Separator #1 */}
			<SeparatorComponent separator={separators[0]} />

			{/* Partners */}
			<Container id='partners'>
				<PartnersComponent
					title={t('words:partners.main')}
					direction={lang === 'en' ? 'left' : 'right'}
					isSlider={true}
					sliders={partners.main}
				/>
			</Container>

			{/* Gallery */}
			<Container
				fluid
				lang={lang ?? 'ar'}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='gallery-section'
			>
				<Container>
					<Row xs={1}>
						{/* Title */}
						<Fade delay={20}>
							<Col className='title text-capitalize text-center'>
								{t('words:ourGallery')}
							</Col>
						</Fade>
					</Row>

					<Row xs={1} sm={2} md={3} className='g-4'>
						{/* Actual Images */}
						{gallery
							?.filter((_, index) => index < MAX_GALLERY_IMAGES)
							?.map((galleryItem, index) => (
								<Fade key={index} direction='up' delay={`${index * 5}`}>
									<Col>
										<GalleryCardComponent
											item={galleryItem}
											index={index}
											setLightbox={setLightbox}
										/>
									</Col>
								</Fade>
							))}

						{/* Lightbox Container */}
						<LightboxComponent
							gallery={gallery}
							lightbox={lightbox}
							setLightbox={setLightbox}
							pathname='<object>.image'
						/>
					</Row>

					<Row xs={1} className='mt-5 overflow-hidden'>
						{/* Action Button */}
						<Fade direction='down' delay={80}>
							<Col className='d-flex justify-content-center align-items-center'>
								<ButtonComponent
									text={t('words:buttons.viewMore')}
									link={routes.gallery.replace(REGEX, function (matched) {
										return replacePathVariables(matched, {
											lang: lang,
										});
									})}
									className='button'
									styles={{
										button: {
											'--hover-bg-color': '#383633',
											'--hover-title-color': '#eae9e5',
										},
									}}
								/>
							</Col>
						</Fade>
					</Row>
				</Container>
			</Container>

			{/* Separator #2 */}
			<SeparatorComponent separator={separators[1]} />

			{/* Testimonials */}
			<Container
				fluid
				lang={lang ?? 'ar'}
				dir={lang === 'en' ? 'ltr' : 'rtl'}
				id='testimonials-section'
			>
				<TestimonialsSliderComponent />
			</Container>
		</Container>
	);
};

export default HomePage;
