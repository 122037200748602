import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Autoplay, EffectFade, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Redux
import { useSelector } from 'react-redux';

// Styles
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import './TestimonialsSliderComponent.styles.css';

// Components
import TestimonialsCardComponent from './../TestimonialsCardComponent/TestimonialsCardComponent';

const TestimonialsSliderComponent = () => {
	// i18next
	const { lang } = useParams();
	const { i18n } = useTranslation();
	useEffect(() => {
		i18n.changeLanguage(lang ?? 'ar');
		// eslint-disable-next-line
	}, [lang]);

	// Redux
	const { testimonials } = useSelector((state) => state.homeData);

	return (
		<Swiper
			lang={lang ?? 'ar'}
			dir={lang === 'en' ? 'ltr' : 'rtl'}
			key={lang ?? 'ar'}
			slidesPerView={1}
			spaceBetween={0}
			loop={true}
			autoplay={{
				delay: 8000,
				disableOnInteraction: false,
				pauseOnMouseEnter: true,
			}}
			grabCursor={true}
			effect={'fade'}
			pagination={{
				enabled: true,
				dynamicBullets: true,
				clickable: true,
			}}
			modules={[Pagination, Autoplay, EffectFade]}
			className='testimonials-slider-component'
		>
			{testimonials?.map((testimonial, index) => (
				<SwiperSlide key={index} className='slider-container'>
					<TestimonialsCardComponent testimonial={testimonial} />
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default TestimonialsSliderComponent;
